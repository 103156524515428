import { Modal } from "bootstrap";
import { ModalInfo } from "./modal-info";

export class SendMessage {
    constructor(api, source = "thereeflog") {
        this.api = api;
        this.source = source;
        this.form = document.querySelector("#send-message");
        this.data = {
            email: this.form.querySelector("#validation-email"),
            full_name: this.form.querySelector("#validation-fullname"),
            subject: this.form.querySelector("#validation-subject"),
            message: this.form.querySelector("#validation-message"),
        };
        this.modal = document.querySelector("#send-message-modal");
        this.setup();
    }

    setup() {
        this.form?.addEventListener("click", async (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (this.form.checkValidity()) {
                const values = {};
                for (const key in this.data) {
                    const value = this.data[key].value;
                    if (value) {
                        values[key] = value;
                    }
                }
                values.source = this.source;
                const [status, _] = await this.api.sendMessage(values);
                if (status === 200) {
                    // show success popup
                    new ModalInfo(
                        this.modal,
                        "Get In Touch!",
                        `Thanks for reaching out ${values.email}.</br></br>We will get back to you very soon!`,
                        "Close",
                        "",
                        true
                    );
                }
                this.clear();
                this.form.classList.remove("was-validated");
            } else {
                this.form.classList.add("was-validated");
            }
        });
    }

    clear() {
        for (const key in this.data) {
            this.data[key].value = "";
        }
    }

    sendMessage() {}
}
