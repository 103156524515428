import { SendMessage } from "../layout/send-message";
import { LogReefAPI } from "../helpers/logreef-api";
import { UserConfigs, userConfig } from "../common/user-config";
import { goToDemo } from "../helpers/demo";
import { updateAuth } from "../helpers/auth";
import { logout } from "../helpers/auth";

const API = new LogReefAPI(userConfig.get(UserConfigs.apiEndpoint));

const init = async () => {
    // logout
    document
        .querySelector(".logout-btn")
        ?.addEventListener("click", (event) => {
            event.preventDefault();
            logout();
            window.location.replace("/index.html");
        });

    // check for demo query parameter
    const urlParams = new URLSearchParams(window.location.search);
    const demoParam = urlParams.get("demo");
    if (demoParam) {
        goToDemo(API);
        return false;
    }

    // initialize send message form
    new SendMessage(API, "thelogreef.index");

    // top bar login or parameters
    const loggedIn = await updateAuth(API);
    if (loggedIn) {
        document.querySelector("#top-bar-logged-in").classList.remove("d-none");
        document.querySelector("#hero-logged-in").classList.remove("d-none");
        document.querySelector("#top-bar-logged-in-text").innerHTML =
            userConfig.get(UserConfigs.username);
    } else {
        document
            .querySelector("#top-bar-not-logged-in")
            .classList.remove("d-none");
        document
            .querySelector("#hero-not-logged-in")
            .classList.remove("d-none");
    }

    // links to demo envirnoment
    document.querySelectorAll("a.go-to-demo").forEach((el) => {
        el.addEventListener("click", async (event) => {
            event.preventDefault();
            await goToDemo(API);
        });
    });

    return true;
};

init().then(async (ok) => {
    if (ok) {
        document.querySelector("main").classList.remove("d-none");
        document.querySelector("#loading-spinner").classList.add("d-none");
    }
});
